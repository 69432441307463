import Banner from './components/banner'
import Header from './components/ui/header'
import './css/style.css'

// import { Architects_Daughter } from 'next/font/google'


/*
const architects_daughter = Architects_Daughter({
  subsets: ['latin'],
  variable: '--font-architects-daughter',
  weight: '400',
  display: 'swap'
})
*/

export const metadata = {
  title: 'Create Next App',
  description: 'Generated by create next app',
}

export default function RootLayout({
  children,
}: {
  children: React.ReactNode
}) {
  return (
        <div className="flex flex-col min-h-screen overflow-hidden">
          Hello
        </div>
  )
}